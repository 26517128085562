<template>
	<div id="wrap">
		<top page="banner" :showSearchButton="false" />
		<div class="mobile-wrap">
			<div class="module-1">
				<div class="mainBox">
					<div class="detail" v-html="focusPic.content"></div>
				</div>
			</div>
			<!-- module-2 -->
		</div>
		<!-- mobile-wrap -->
		<bottom />
		<side />
	</div>
</template>

<script>
import top from "./components/top.vue";
import bottom from "./components/bottom.vue";
import side from "./components/side.vue";
export default {
	data() {
		return {
			focusPic: {}
		}
	},
	methods: {
		getSingle() {
			let id = this.$route.params.id

			this.$api.focus.focusPic.getSingle(id)
			.then(response => {
				if(response.code === '0') {
					this.focusPic = response.data

					document.title = this.focusPic.name
				}
			})
		}
	},
	components: {
		top,
		bottom,
		side
	},
	mounted() {
		this.getSingle()
	}
}
</script>